import {
  IResourceComponentsProps,
  useTranslate,
  useCan
} from "@pankod/refine-core"
import { IWorkspaceSettings, VisitType, WorkSpaceVisitTypes } from "interfaces"
import React, { useEffect, useState } from "react"
import { useAppSelector } from "reduxStore/store"
import { axiosInstance } from "utilities/dataProvider"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "utilities/types"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { LOG, Logger } from "utilities/logger"
import { BatteryChargingIcon, BellIcon, PaletteIcon, Settings2Icon, ShieldIcon, UserRoundCogIcon, UsersRoundIcon } from "lucide-react"
import { CategoryButton } from "./CategoryButton"
import { SettingCategoryContainer } from "./SettingsCategoryContainer"

export enum SettingCategory {
  General = "general",
  Guest = "guest",
  Notifications = "notifications",
  VisitType = "visitType",
  Branding = "branding",
  EnergySaver = "energySaver",
  Flow = "flow",
  Privacy = "privacy",
}

export const SettingsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate()

  const [selectedCategory, setSelectedCategory] = useState<SettingCategory>(SettingCategory.General)
  const [selectedVisitType, setSelectedVisitType] = useState<VisitType | undefined>()

  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const queryClient = useQueryClient()

  const { data: canAccessVisitModule } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  const { data: canAccessRoomsModule } = useCan({
    resource: "rooms",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["rooms"]
        }
      }
    }
  })

  useEffect(() => {
    // Always invalidate queries when component mounts
    queryClient
      .invalidateQueries([QUERY_KEYS.WORKSPACE_SETTING, QUERY_KEYS.WORKSPACE_VISIT_TYPES])
      .then(() => {
        // Successfully invalidated the cache, forcing refetch
      })
      .catch((error) => {
        void Logger().error(LOG.WORKSPACE_SETTINGS, `${error}`)
      })
  }, [queryClient])

  const { data, isLoading } = useQuery<IWorkspaceSettings>(
    [QUERY_KEYS.WORKSPACE_SETTING],
    async () => {
      const res = await axiosInstance.get(
        `/workspaces/${selectedWorkspaceId}/settings`
      )
      return res.data
    },
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (e) => {
        void Logger().error(LOG.WORKSPACE_SETTINGS, `${e}`)
      }
    }
  )

  const { data: workspaceVisitTypes } = useQuery<WorkSpaceVisitTypes>([QUERY_KEYS.WORKSPACE_VISIT_TYPES],
    async () => {
      const res = await axiosInstance.get(
        `/workspaces/${selectedWorkspaceId}/visit-types`
      )
      return res.data
    },
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (e) => {
        void Logger().error(LOG.WORKSPACE_VISIT_TYPES, `${e}`)
      }
    }
  )

  return (
    <div className="flex flex-row justify-between w-full gap-4">
      <div className="flex flex-col gap-2 w-72">
        <p className="text-md">
          {translate("pages.settings.WorkspaceSettings")}
        </p>
        {canAccessVisitModule && (
          <>
            {/* General visit workspace settings */}
            <div className="flex flex-col">
              <CategoryButton
                category={SettingCategory.General}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                icon={<Settings2Icon />} />
              <CategoryButton
                category={SettingCategory.Notifications}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                icon={<BellIcon />} />
              {/* TODO: Figma Excluded emails */}
              <CategoryButton
                category={SettingCategory.Guest}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                icon={<UserRoundCogIcon />} />
              {/* Temporarily show 'Flow' in here.
                    In Figma, these should are under visit types */}
              <CategoryButton
                category={SettingCategory.Flow}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                icon={<UsersRoundIcon />} />
              <CategoryButton
                category={SettingCategory.Privacy}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                icon={<ShieldIcon />} />
            </div>
            <p className="text-sm text-one-gray-500 font-medium">
              {translate("pages.settings.category.visitTypes")}
            </p>
            {/* Visit type settings */}
            <div className="flex flex-col">
              {workspaceVisitTypes?.visit_types?.map((visitType, index) => (
                <CategoryButton
                  key={visitType.id}
                  category={SettingCategory.VisitType}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  visitType={visitType}
                  visitTypeIndex={index + 1}
                  selectedVisitType={selectedVisitType}
                  setSelectedVisitType={setSelectedVisitType}
                />
              ))}
            </div>
          </>
        )}
        {canAccessRoomsModule && (
          <>
            {/* Rooms workspace settings */}
            <p className="text-sm text-one-gray-500 font-medium">
              {translate("pages.settings.category.rooms")}
            </p>
            <div className="flex flex-col">
              <CategoryButton
                category={SettingCategory.Branding}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                icon={<PaletteIcon />} />
              <CategoryButton
                category={SettingCategory.EnergySaver}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                icon={<BatteryChargingIcon />} />
            </div>
          </>
        )}
      </div>
      {/* Container for settings */}
      {isLoading ? (
        <div className="justify-start w-full h-full">
          <SkeletonTheme baseColor="#f3f4f6" highlightColor="#fff">
            <Skeleton count={3}
              height={250} />
          </SkeletonTheme>
        </div>
      ) : (
        <SettingCategoryContainer category={selectedCategory} data={data} visitType={selectedVisitType} />
      )}
    </div>
  )
}
