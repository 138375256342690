import { useOne, useQuery, useTranslate } from "@pankod/refine-core"
import { IWorkspaceLanguages, IWorkspaceSettings } from "interfaces"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { Language, QUERY_KEYS } from "utilities/types"
import { PolicyDialogOption } from "./PolicyDialogOption"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { WarningTextContainer } from "./WarningTextContainer"
import { axiosInstance } from "utilities/dataProvider"

export const PrivacyPolicy = () => {
    const translate = useTranslate()
    const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) ?? ""

    const { data: allLanguages } = useOne<Language[]>({
        resource: "languages",
        id: ""
    })

    const { data: workspaceLanguages } = useOne<IWorkspaceLanguages>({
        resource: `workspaces/${selectedWorkspaceId}/languages`,
        id: ""
    })

    const { data: workspaceSettings, isLoading } = useQuery<IWorkspaceSettings>(
        [QUERY_KEYS.WORKSPACE_SETTING],
        async () => {
            const res = await axiosInstance.get(`workspaces/${selectedWorkspaceId}/settings`)
            return res.data
        }
    )

    const getLanguageKey = (language: string) => {
        switch (language) {
            case "en-US":
                return "PrivacyPolicyEn"
            case "fi-FI":
                return "PrivacyPolicyFi"
            case "da-DK":
                return "PrivacyPolicyDk"
            case "ro-RO":
                return "PrivacyPolicyRo"
            case "pl-PL":
                return "PrivacyPolicyPl"
            default:
                return "PrivacyPolicyEn"
        }
    }

    const defaultLanguage = workspaceLanguages?.data.languages.find((language) => language.is_primary)
    const isMissingPolicy = (languages: Language[], settings: IWorkspaceSettings | undefined) => {
        for (const language of languages) {
            if (workspaceLanguages?.data.languages.find((lang) => lang.language.id === language.id) === undefined) return false
            const settingKey = getLanguageKey(language?.tag ?? "")
            const privacyPolicy = settings?.settings?.find((setting) => setting.setting_metadata.key === settingKey)
            const initialValue = privacyPolicy?.value ?? ""
            if (initialValue === "") {
                return true
            }
        }
        return false
    }

    const renderPolicyDialogOptions = (languages: Language[], settings: IWorkspaceSettings | undefined) => {
        return languages.map((language) => {
            if (workspaceLanguages?.data.languages.find((lang) => lang.language.id === language.id) === undefined) return null
            if (settings === undefined) return null

            const settingKey = getLanguageKey(language?.tag ?? "")
            const privacyPolicy = settings?.settings?.find((setting) => setting.setting_metadata.key === settingKey)
            const initialValue = privacyPolicy?.value ?? ""

            if (privacyPolicy && defaultLanguage) {
                return (
                    <PolicyDialogOption
                        key={language.id}
                        language={language}
                        defaultLanguage={defaultLanguage}
                        settingKey={settingKey}
                        languageTerms={privacyPolicy}
                        initialValue={initialValue}
                    />
                )
            }

            return null
        })
    }

    const missingPolicy = isMissingPolicy(allLanguages?.data ?? [], workspaceSettings)

    return (
        <>
            {!isLoading && missingPolicy && (
                <div className="mb-2">
                    <WarningTextContainer>
                        <p className="font-bold">{translate("pages.settings.warnings.missingPrivacyPolicy")}</p>
                        {translate("pages.settings.warnings.missingPrivacyPolicyDescription")}
                    </WarningTextContainer>
                </div>
            )}
            <div className="w-full rounded-md border bg-white shadow-sm mb-2">
                {isLoading ?
                    (
                        <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
                            <Skeleton
                                count={1}
                                height={230}
                                circle={false}
                                enableAnimation={true}
                            />
                        </SkeletonTheme>
                    ) :
                    renderPolicyDialogOptions(allLanguages?.data ?? [], workspaceSettings)
                }
            </div>
        </>
    )
}
