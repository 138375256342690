/**
 * Represents the available role types within the system.
 *
 * @typedef {string} RoleName
 * - 'OrganizationOwner' - Highest level role with full organizational access and control
 * - 'WorkspaceAdmin' - Administrative role for managing specific workspace settings and users
 * - 'FrontDesk' - Role for handling front desk operations and visitor management
 * - 'BasicUser' - Standard user role with limited access to basic features
 */
export type RoleName =
  | "OrganizationOwner"
  | "WorkspaceAdmin"
  | "FrontDesk"
  | "BasicUser"

/**
 * An array of all available role names within the system.
 *
 * @type {RoleName[]} ROLE_NAMES
 * @readonly
 */
export const ROLE_NAMES: RoleName[] = [
  "OrganizationOwner",
  "WorkspaceAdmin",
  "FrontDesk",
  "BasicUser"
]

/**
 * A type that represents a normalized (lowercase) role name.
 * @template RoleName - The original role name type to be normalized
 */
export type NormalizedRoleName = Lowercase<RoleName>
