import {
  useCustomMutation,
  usePermissions,
  useQueryClient,
  useTranslate
} from "@pankod/refine-core"
import { Button } from "components/globals/Button"
import { ISettingEntity } from "interfaces"
import { ChangeEvent, useEffect, useState } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { axiosNoUrl, axiosInstance } from "utilities/dataProvider"
import { checkSettingTranslation } from "utilities/helpers"
import { LOG, Logger } from "utilities/logger"
import { invalidateQueries } from "utilities/queryClientHelper"
import customToast from "utilities/toastHelper"

export const SettingPictureUpload = ({
  settingsData: {
    value,
    setting_metadata: { key, data_type }
  },
  visitTypeId
}: {
  settingsData: ISettingEntity
  visitTypeId?: number
}) => {
  const [settingValue, setSettingValue] = useState(value)
  const [settingDataType, setSettingDataType] = useState("")
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  )
  const [imgPreview, setImgPreview] = useState("")
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")
  const translate = useTranslate()
  const { mutate: customMutation } = useCustomMutation()
  const description = checkSettingTranslation(translate(`pages.settings.${key}Description`))
  const queryClient = useQueryClient()

  useEffect(() => {
    setSettingValue(value)
  }, [value])

  const selectedSetting = key

  useEffect(() => {
    if (selectedSetting !== undefined) {
      if (data_type !== undefined) {
        setSettingDataType(data_type.name)
      }
    }
  }, [selectedSetting])

  const workspaceId = localStorage.getItem(SELECTED_WORKSPACE) || ""
  const endpoint = visitTypeId ? `/visit-types/${visitTypeId}/settings/${key}` : `/workspaces/${workspaceId}/settings/${key}`

  const updateSetting = (value: string) => {
    if (settingDataType !== "Picture") {
      return
    }

    customMutation(
      {
        url: endpoint,
        values: { value },
        method: visitTypeId ? "patch" : "put"
      },
      {
        onSuccess: (_data, _variables, _context) => {
          void invalidateQueries(queryClient, visitTypeId)
          customToast.success(translate(`notifications.${key}Success`))
        },
        onError: (_error, _variables, _context) => {
          customToast.error(translate(`notifications.${key}Error`))
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          void Logger().error(LOG.EDIT_SETTING, `${_error.stack}`)
        }
      }
    )
  }

  const resetButtonStyle = "px-1 text-soft-blue"

  let inputStyle: string | undefined

  switch (settingDataType) {
    case "Color":
      inputStyle =
        "border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-500 focus:border-2 w-8 h-8"
      break
    default:
      inputStyle =
        "bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:border-2 block p-2.5 w-full"
      break
  }

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const file = target.files?.[0]
    setSelectedImage(file)
  }

  useEffect(() => {
    const uploadPhoto = async () => {
      let publicUrl = ""

      const getSignedUrl = async () => {
        if (selectedImage !== undefined) {
          try {
            const res = await axiosInstance.post(
              `/workspaces/${workspaceId}/settings/signed-url`,
              {
                blob: selectedImage?.name
              }
            )
            const signedUrl: string = res.data.signed_url
            publicUrl = res.data.public_url

            void axiosNoUrl.put(signedUrl, selectedImage, {
              headers: {
                "Content-type": selectedImage.type
              }
            })
          } catch (error) {
            customToast.error(translate("notifications.fileUploadError"))
            void Logger().error(LOG.SIGNED_URL, `${error}`)
          }
        }
      }
      await getSignedUrl()
      setSettingValue(publicUrl)
      updateSetting(publicUrl)
    }

    if (selectedImage !== undefined) {
      const objectUrl = URL.createObjectURL(selectedImage)
      setImgPreview(objectUrl)
      void uploadPhoto()

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [selectedImage])

  return (
    <div>
      {settingDataType === "Picture" && (
        <div className="w-full pb-4">
          <div className="flex flex-col flex-1 mb-2 mt-2">
            <div className="flex flex-col justify-between mb-1">
              <h1 className="self-start mb-2 font-medium text-md">
                {translate(`pages.settings.${selectedSetting}`)}
              </h1>
              <input
                disabled={!canEdit}
                type="file"
                className={inputStyle}
                onChange={(e) => onChangeFile(e)}
              ></input>
            </div>
            <p className="text-gray-500 text-sm whitespace-pre-wrap">
              {description && translate(description)}
            </p>
          </div>
          {settingValue !== "false" && settingValue !== "" ? (
            <div className="flex flex-col pt-2 mb-2 items-start">
              <img
                id="preview"
                className="inset-0 pb-2 object-scale-down max-h-32 max-w-64"
                alt="Preview"
                src={imgPreview !== "" ? imgPreview : settingValue}
              />
              <Button
                name="Reset"
                style={`${resetButtonStyle}`}
                onClick={() => {
                  setSettingValue("")
                  updateSetting("")
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col pt-2 text-sm">
              <p>{translate("pages.devices.noImage")}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
