import { useOne } from "@pankod/refine-core"
import { IWorkspaceLanguages, VisitType, } from "interfaces"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { Language } from "utilities/types"
import { PolicyDialogOption } from "./PolicyDialogOption"

export const TermsOfVisit = ({ visitType }: { visitType?: VisitType }) => {
    const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) ?? ""

    const { data: allLanguages } = useOne<Language[]>({
        resource: "languages",
        id: ""
    })

    const { data: workspaceLanguages } = useOne<IWorkspaceLanguages>({
        resource: `workspaces/${selectedWorkspaceId}/languages`,
        id: ""
    })

    const getLanguageKey = (language: string) => {
        switch (language) {
            case "en-US":
                return "TermsOfVisitEn"
            case "fi-FI":
                return "TermsOfVisitFi"
            case "da-DK":
                return "TermsOfVisitDk"
            case "ro-RO":
                return "TermsOfVisitRo"
            case "pl-PL":
                return "TermsOfVisitPl"
            default:
                return "TermsOfVisitEn"
        }
    }

    const defaultLanguage = workspaceLanguages?.data.languages.find((language) => language.is_primary)

    return (
        <div className="w-full rounded-md border bg-white shadow-sm mb-2">
            {allLanguages?.data?.map((language) => {
                if (workspaceLanguages?.data.languages.find((lang) => lang.language.id === language.id) === undefined) return null

                const settingKey = getLanguageKey(language?.tag ?? "")

                let languageTerms
                if (visitType) {
                    languageTerms = visitType.settings.find((setting) => setting.setting_metadata.key === settingKey)
                    const initialValue = languageTerms?.value ?? ""
                    const visitTypeId = visitType.id

                    if (languageTerms && defaultLanguage) {
                        return (
                            <PolicyDialogOption
                                key={language.id}
                                language={language}
                                defaultLanguage={defaultLanguage}
                                settingKey={settingKey}
                                visitTypeId={visitTypeId}
                                languageTerms={languageTerms}
                                initialValue={initialValue} />)
                    }
                }

                return null
            })}
        </div>
    )
}
