import { useCustomMutation, useQueryClient, useTranslate } from "@pankod/refine-core"
import { Button } from "components/ui/button"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog"
import { ISettingEntity, WorkspaceLanguage } from "interfaces"
import { AlertTriangle, CircleAlert, CircleCheck, } from "lucide-react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { LOG, Logger } from "utilities/logger"
import { Language } from "utilities/types"
import { Textarea } from "components/ui/textarea"
import { useEffect, useState } from "react"
import { invalidateQueries } from "utilities/queryClientHelper"
import customToast from "utilities/toastHelper"

export const PolicyDialogOption = ({
    language,
    defaultLanguage,
    settingKey,
    visitTypeId,
    languageTerms,
    initialValue
}: {
    language: Language
    defaultLanguage: WorkspaceLanguage
    settingKey: string
    visitTypeId?: number
    languageTerms: ISettingEntity
    initialValue: string
}) => {
    const translate = useTranslate()
    const queryClient = useQueryClient()
    const { mutate: updateMutation } = useCustomMutation()

    const [value, setValue] = useState(initialValue)
    const [currentInitialValue, setCurrentInitialValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
        setCurrentInitialValue(initialValue)
    }, [initialValue])

    const isPrivacyPolicy = settingKey.startsWith("PrivacyPolicy")
    const endpoint = visitTypeId ? `visit-types/${visitTypeId}/settings/${settingKey}` : `workspaces/${localStorage.getItem(SELECTED_WORKSPACE)}/settings/${settingKey}`

    const handleSave = () => {
        if (value === currentInitialValue) return
        updateMutation(
            {
                url: endpoint,
                method: "patch",
                values: { value: value }
            },
            {
                onSuccess: (_data, _variables, _context) => {
                    void invalidateQueries(queryClient, visitTypeId)
                    customToast.success(
                        translate("notifications.editSuccess", {
                            resource: translate(`resources.${settingKey}`)
                        })
                    )
                    void Logger().log(LOG.EDIT_SETTING, settingKey)
                    setCurrentInitialValue(value)
                },
                onError: (_error) => {
                    customToast.error(
                        translate("notifications.editError", {
                            resource: translate(`resources.${settingKey}`)
                        })
                    )
                    void Logger().error(LOG.EDIT_SETTING, `${settingKey}: ${_error}`)
                }
            }
        )
    }

    let icon
    if (languageTerms?.value) {
        icon = <CircleCheck className="h-5 w-5 text-emerald-500" />
    } else if (language.id === defaultLanguage?.language.id) {
        icon = <CircleAlert className="h-5 w-5 text-red-500" />
    } else {
        icon = <AlertTriangle className="h-5 w-5 text-amber-500" />
    }

    return (
        <Dialog key={language.id}
            onOpenChange={() => setValue(currentInitialValue)}>
            <DialogTrigger asChild>
                <Button
                    variant="ghost"
                    className="w-full flex items-center justify-between px-4 py-3 hover:bg-gray-50"
                >
                    <div className="flex items-center gap-2 ">
                        {icon}
                        <span>
                            {language.name}
                            {language.id === defaultLanguage?.language.id && (
                                <span className="ml-2 text-one-gray-500 text-sm">{translate("pages.settings.default")}</span>
                            )}
                        </span>
                    </div>
                    <span className="text-gray-400">&gt;</span>
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {translate(isPrivacyPolicy ? "pages.settings.editPrivacyPolicy" : "pages.settings.editTermsOfVisit")}
                    </DialogTitle>
                    <DialogDescription>
                        {translate(isPrivacyPolicy ? "pages.settings.privacyPolicyDescription" : "pages.settings.termsOfVisitDescription")}
                    </DialogDescription>
                </DialogHeader>
                <h1 className="text-2xl">
                    {language.name}
                </h1>
                <div className="py-4">
                    {languageTerms &&
                        <Textarea
                            data-testid={`textarea-${settingKey}`}
                            className="min-h-[200px] w-full"
                            value={value}
                            onChange={(event) => setValue(event.target.value)} />
                    }
                </div>
                <DialogFooter>
                    <div className="flex flex-row gap-2">
                        <DialogClose asChild>
                            <Button variant="outline">
                                {translate("buttons.cancel")}
                            </Button>
                        </DialogClose>
                        <DialogClose asChild>
                            <Button variant="default" onClick={() => {
                                handleSave()
                            }}
                            >
                                {translate("buttons.save")}
                            </Button>
                        </DialogClose>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}