import { ISettingEntity } from "interfaces"
import { SettingBoolean } from "./builder-components/SettingBoolean"
import { SettingDropdown } from "./builder-components/SettingDropdown"
import { SettingSingleLineText } from "./builder-components/SettingSingleLineText"
import { SettingTextArea } from "./builder-components/SettingTextArea"
import { SettingPictureUpload } from "./builder-components/SettingPictureUpload"
import { SettingPrintingBoolean } from "./builder-components/SettingPrintingBoolean"
import { SettingPreArrivalEmailBody } from "./builder-components/SettingPreArrivalEmailBody"
import { SettingTime } from "./builder-components/SettingTime"
import { SettingTimeZone } from "./builder-components/SettingTimeZone"

/**
 *  SettingBuilder returns react component based on settings data_type
 */
export const SettingBuilder = (settingsData: ISettingEntity, index: number, dataTypeOverride?: string, visitTypeId?: number) => {
  switch (dataTypeOverride ?? settingsData.setting_metadata.data_type.name) {
    case "PreArrivalEmailBody":
      return <SettingPreArrivalEmailBody key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    case "PrintingBoolean":
      return <SettingPrintingBoolean key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    case "Boolean":
      return <SettingBoolean key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    case "Picture":
      return <SettingPictureUpload key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    case "TextArea":
      return <SettingTextArea key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    case "Dropdown":
      return <SettingDropdown key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    case "Time":
      return <SettingTime key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    case "TimeZone":
      return <SettingTimeZone key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
    default:
      return <SettingSingleLineText key={index} settingsData={settingsData} visitTypeId={visitTypeId} />
  }
}

