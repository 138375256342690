import { UseModalFormReturnType } from "@pankod/refine-react-hook-form"
import { useTranslate, useUpdate } from "@pankod/refine-core"
import { useState, useEffect, useRef, ChangeEvent } from "react"
import { Modal } from "../../components/Modal"
import { IMandatoryFields } from "interfaces"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import ErrorText from "components/formMessage"
import validators from "utilities/formValidator"
import { axiosInstance, axiosNoUrl } from "utilities/dataProvider"
import { isEmptyString } from "utilities/string"
import { useQueryClient } from "@tanstack/react-query"
import { Button, ButtonStyle } from "components/globals/Button"
import { Input } from "components/globals/Input"
import { RoleDropdown } from "components/dropdown/RoleDropdown"
import { useAppSelector } from "reduxStore/store"
import { UserIcon } from "@heroicons/react/24/outline"

export const EditUser: React.FC<
  UseModalFormReturnType & { userId: number }
> = ({
  refineCore: { queryResult, formLoading },
  modal: { visible, close },
  userId
}) => {
  const translate = useTranslate()
  const { mutate } = useUpdate() // TODO is <Type> needed here ..?
  const workspaceId = useAppSelector((state) => state.workspace)

  // TODO: Set these values somewhere more global
  const mandatoryFields: IMandatoryFields = {
    firstName: true,
    lastName: true,
    phone: false,
    email: true
  }

  const [errors, setErrors] = useState<string[]>([])

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [picture, setPicture] = useState("")
  const [roleId, setRoleId] = useState("")

  const [preview, setPreview] = useState("")
  const [bucketImage, setBucketImage] = useState("")
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  )
  const inputFile = useRef<HTMLInputElement | null>(null)
  const queryClient = useQueryClient()
  const openFileBrowser = () => {
    // `current` points to the mounted file input element
    inputFile.current?.click()
  }

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const file = target.files?.[0]

    setSelectedImage(file)
  }

  useEffect(() => {
    if (!formLoading) {
      const roleId = queryResult?.data?.data.role.id
      const roleIdStr = roleId
        ? typeof roleId === "number"
          ? roleId.toString()
          : (roleId as string)
        : ""

      setFirstName((queryResult?.data?.data.person.first_name as string) ?? "")
      setLastName((queryResult?.data?.data.person.last_name as string) ?? "")
      setEmail((queryResult?.data?.data.person.email as string) ?? "")
      setPhone((queryResult?.data?.data.person.phone as string) ?? "")
      setPicture((queryResult?.data?.data.picture as string) ?? "")
      setRoleId(roleIdStr)
      setErrors([])
      setBucketImage("")
      setPreview("")
    }
  }, [formLoading])

  const cancelPreview = () => {
    setPreview("")
  }

  const deletePhoto = () => {
    setPicture("")
    setBucketImage("")
    setPreview("")
    setSelectedImage(undefined)
  }

  useEffect(() => {
    const uploadPhoto = async () => {
      let publicUrl = ""

      const getSignedUrl = async () => {
        if (selectedImage !== undefined) {
          try {
            const res = await axiosInstance.post(`/users/me/signed-url`, {
              blob: selectedImage?.name
            })
            const signedUrl: string = res.data.signed_url
            publicUrl = res.data.public_url

            void axiosNoUrl.put(signedUrl, selectedImage, {
              headers: {
                "Content-type": selectedImage.type
              }
            })
          } catch (error) {
            customToast.error(translate("notifications.fileUploadError"))
            void Logger().error(LOG.SIGNED_URL, `${error}`)
          }
        }
      }
      await getSignedUrl()

      setBucketImage(publicUrl)
    }

    if (!selectedImage) {
      setPreview("")
    } else if (selectedImage !== undefined) {
      const objectUrl = URL.createObjectURL(selectedImage)
      setPreview(objectUrl)
      void uploadPhoto()

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [selectedImage])

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    void Logger().log(LOG.EDIT_USER)

    const pic = !isEmptyString(bucketImage)
      ? bucketImage
      : e.target["picture"].value

    if (validate()) {
      const userId = queryResult?.data?.data.id
      mutate(
        {
          resource: `users`,
          id: userId as number,
          values: {
            role_id: roleId,
            email: e.target["email"].value,
            phone: e.target["phone"].value,
            picture: pic,
            first_name: e.target["firstName"].value,
            last_name: e.target["lastName"].value,
            workspaces: [
              // For now there is only the selected workspace. In future add checklist for multple
              workspaceId
            ]
          }
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.editError", {
                resource: translate("resources.user")
              })
            )
            void Logger().error(LOG.EDIT_USER, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            customToast.success(
              translate("notifications.editSuccess", {
                resource: translate("resources.user")
              })
            )
            close()
          },
          onSettled(data, error, variables, context) {
            setTimeout(() => void queryClient.invalidateQueries(), 100)
          }
        }
      )
    }
  }

  /**
   * Validate fields
   * @returns True if no errors
   */
  function validate(): boolean {
    const validationErrors = validators.handleValidation(
      firstName,
      lastName,
      phone,
      email,
      mandatoryFields
    )

    if (roleId === "") {
      validationErrors["role"] = translate("pages.users.selectRole")
    }

    setErrors(validationErrors)
    return validationErrors["firstName"] ||
      validationErrors["lastName"] ||
      validationErrors["email"] ||
      validationErrors["phone"] ||
      validationErrors["role"]
      ? false
      : true
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    if (id === "firstName") {
      setFirstName(value as string)
    }
    if (id === "lastName") {
      setLastName(value as string)
    }
    if (id === "email") {
      setEmail(value as string)
    }
    if (id === "phone") {
      setPhone(value as string)
    }
    if (id === "picture") {
      setPicture(value as string)
    }
  }

  return (
    <Modal
      title={translate("pages.users.editUser")}
      isOpen={visible}
      onClose={close}
    >
      <form className="form" onSubmit={onSubmit} autoComplete="off">
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={(e) => onChangeFile(e)}
        />
        <div className="flex flex-col gap-2 px-6">
          <div>
            <div className="flex flex-col gap-4 pb-6">
              <div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-1 items-center justify-center">
                    {preview || picture ? (
                      <img
                        alt="userpic"
                        width="96"
                        height="96"
                        className="rounded-full object-cover aspect-square"
                        src={preview ? preview : picture}
                      ></img>
                    ) : (
                      <div>
                        <UserIcon className="h-14 w-14 text-systam-blue" />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-1 items-center justify-start">
                    <div className="flex-col">
                      <div>
                        <Button
                          type="button"
                          onClick={openFileBrowser}
                          style={ButtonStyle.BluePrimary}
                          name={
                            !preview && !picture
                              ? translate("form.addPhoto")
                              : translate("form.changePhoto")
                          }
                        />
                      </div>
                      {picture && !preview && (
                        <div>
                          <Button
                            type="button"
                            onClick={deletePhoto}
                            style={ButtonStyle.TransparentNoBorder}
                            name={translate("form.deletePhoto")}
                          />
                        </div>
                      )}
                      {preview && (
                        <>
                          <div>
                            <Button
                              type="button"
                              style={ButtonStyle.TransparentBorder}
                              onClick={cancelPreview}
                              name={translate("buttons.cancel")}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between gap-4">
            <div className="flex flex-1">
              <Input
                label={translate("form.firstName")}
                placeholder={translate("form.firstName")}
                id="firstName"
                type="text"
                value={firstName}
                required={mandatoryFields.firstName}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="flex flex-1">
              <Input
                label={translate("form.lastName")}
                placeholder={translate("form.lastName")}
                id="lastName"
                type="text"
                value={lastName}
                required={mandatoryFields.lastName}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-4 -mt-1">
            <div className="flex flex-1">
              {errors["firstName"] && ErrorText(errors["firstName"])}
            </div>
            <div className="flex flex-1">
              {errors["lastName"] && ErrorText(errors["lastName"])}
            </div>
          </div>
          <div>
            <Input
              label={translate("form.email")}
              placeholder={translate("form.email")}
              id="email"
              type="text"
              value={email}
              required={mandatoryFields.email}
              onChange={(e) => handleInputChange(e)}
            />
            <div className="pt-1">
              {errors["email"] && ErrorText(errors["email"])}
            </div>
          </div>
          <div>
            <Input
              label={translate("form.phone")}
              placeholder={translate("form.phone")}
              id="phone"
              type="text"
              value={phone}
              required={mandatoryFields.phone}
              onChange={(e) => handleInputChange(e)}
            />
            <div className="pt-1">
              {errors["phone"] && ErrorText(errors["phone"])}
            </div>
          </div>
          <div>
            <input
              type="text"
              value={picture}
              onChange={(e) => handleInputChange(e)}
              id="picture"
              placeholder={translate("form.pictureUrl")}
              style={{ display: "none" }}
            />
          </div>
          <div>
            <RoleDropdown
              roleId={roleId}
              editingUserId={userId}
              onRoleIdChange={(roleId) => {
                setRoleId(roleId as string)
              }}
            />
            <div className="pt-1">
              {errors["role"] && ErrorText(errors["role"])}
            </div>
          </div>
          <div className="py-8 flex justify-around">
            <Button
              style={ButtonStyle.TransparentBorder}
              onClick={() => {
                void Logger().log(LOG.PRESS_CANCEL_EDIT_USER_MODAL)
                close()
              }}
              name={translate("buttons.cancel")}
            />
            <Button
              type="submit"
              style={ButtonStyle.BluePrimary}
              name={
                formLoading ? translate("loading") : translate("buttons.save")
              }
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}
