import { QueryClient } from "@pankod/refine-core";
import { Logger, LOG } from "utilities/logger";
import { QUERY_KEYS } from "utilities/types";

export const invalidateQueries = async (
    queryClient: QueryClient,
    visitTypeId?: number
) => {
    try {
        if (visitTypeId) {
            await queryClient.invalidateQueries([QUERY_KEYS.WORKSPACE_VISIT_TYPES]);
        } else {
            await queryClient.invalidateQueries([QUERY_KEYS.WORKSPACE_SETTING]);
        }
    } catch (error) {
        if (visitTypeId) {
            void Logger().error(LOG.WORKSPACE_VISIT_TYPES, `${error}`);
        } else {
            void Logger().error(LOG.WORKSPACE_SETTINGS, `${error}`);
        }
    }
};