import { useGetIdentity, useTranslate } from "@pankod/refine-core"
import { SyLabel, SySelect } from "components/new/shared"
import { useRoleManagement } from "modules/Role/useRoleManagement"

interface RoleDropdownProps {
  roleId?: string
  onRoleIdChange: (roleId: string | undefined) => void
  editingUserId?: number
}

export const RoleDropdown = ({
  roleId,
  onRoleIdChange,
  editingUserId
}: RoleDropdownProps) => {
  const { data: identity } = useGetIdentity<{ id: number; username: string }>()
  const currentUserId = identity?.id
  const translate = useTranslate()
  const { roleOptions, isLoading, error, storedUserRole } = useRoleManagement(
    currentUserId,
    editingUserId,
    roleId
  )

  if (error) {
    return <div>Error loading roles: {error.message}</div>
  }

  if (isLoading) {
    return <div>Loading roles...</div>
  }

  if (!storedUserRole) {
    return <div>No user role available</div>
  }

  return (
    <div>
      <SyLabel required>{translate("pages.users.role")}</SyLabel>
      <SySelect
        id="roleId"
        placeholder={translate("pages.users.selectRole")}
        options={roleOptions}
        value={roleId}
        onValueChange={onRoleIdChange}
      />
    </div>
  )
}
