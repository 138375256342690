import axios, { AxiosInstance } from "axios"
import { ACCESS_TOKEN_KEY, USER_ID_KEY } from "utilities/constants"

export enum LOG {
  ACCESS_CONTROL_PROVIDER = "access_control_provider",
  AUTH_PROVIDER = "auth_provider",
  CALENDAR_INTEGRATION = "calendar_integration",
  CHANGE_LANGUAGE = "change_language",
  CHANGE_PAGE = "change_page",
  CHANGE_PASSWORD = "change_password",
  CHANGE_PASSWORD_SUCCESS = "change_password_success",
  CREATE_DEVICE = "create_device",
  CREATE_HOST = "create_host",
  CREATE_USER = "create_user",
  CREATE_VISIT_ERROR = "create_visit_error",
  CREATE_VISITOR = "create_visitor",
  DATA_PROVIDER = "data_provider",
  DEL_HOST = "del_host",
  DEL_USER = "del_user",
  DEL_CALENDAR_INTEGRATION = "del_calendar_integration",
  DEL_VISITOR = "del_visitor",
  EDIT_HOST = "edit_host",
  EDIT_SETTING = "edit_setting",
  EDIT_USER = "edit_user",
  EDIT_USER_PROFILE = "edit_user_profile",
  EDIT_VISIT = "edit_visit",
  EDIT_VISITOR = "edit_visitor",
  ERROR_AXIOS_INTERCEPTOR = "error_axios_interceptor",
  ERROR_BOUNDARY = "error_boundary",
  EXPORT_VISITS = "export_visits",
  FILTER_PAGINATION_ERROR = "filter_pagination_error",
  HOST_SEARCH = "host_search",
  I18N = "i18n",
  IMPORT_CSV = "import_csv",
  IMPORT_HOST = "import_host",
  INFINITE_SCROLL = "infinite_scroll",
  LINK_DEVICE = "link_device",
  LOCAL_STORAGE = "local_storage",
  REGISTER_SUCCESS = "register_success",
  REGISTER_ERROR = "register_error",
  LOGIN = "login",
  LOGOUT = "logout",
  MSAL = "Msal",
  NOTIFY_HOST_VISITOR_ARRIVED = "notify_host_visitor_arrived",
  PRESS_CANCEL_CREATE_HOST_MODAL = "press_cancel_create_host_modal",
  PRESS_CANCEL_CREATE_USER_MODAL = "press_cancel_create_user_modal",
  PRESS_CANCEL_DEL_HOST_MODAL = "press_cancel_del_host_modal",
  PRESS_CANCEL_DEL_USER_MODAL = "press_cancel_del_user_modal",
  PRESS_CANCEL_DEL_VISITOR_MODAL = "press_cancel_del_visitor_modal",
  PRESS_CANCEL_EDIT_HOST_MODAL = "press_cancel_edit_host_modal",
  PRESS_CANCEL_EDIT_USER_MODAL = "press_cancel_edit_user_modal",
  PRESS_CANCEL_IMPORT_HOST_MODAL = "press_cancel_import_host_modal",
  PRESS_CANCEL_IMPORT_USER_MODAL = "press_cancel_import_user_modal",
  PRESS_CANCEL_DEL_INTEGRATION_MODAL = "press_cancel_del_integration_modal",
  PRESS_CREATE_HOST_MODAL = "press_create_host_modal",
  PRESS_CREATE_USER_MODAL = "press_create_user_modal",
  PRESS_CREATE_VISIT_NAV = "press_create_visit_nav",
  PRESS_CREATE_VISITOR_MODAL = "press_create_visitor_modal",
  PRESS_DEL_HOST_MODAL = "press_del_host_modal",
  PRESS_DEL_USER_MODAL = "press_del_user_modal",
  PRESS_DEL_VISITOR_MODAL = "press_del_visitor_modal",
  PRESS_DOWNLOAD_APK = "press_download_apk",
  PRESS_EDIT_HOST_MODAL = "press_edit_host_modal",
  PRESS_EDIT_USER_MODAL = "press_edit_user_modal",
  PRESS_EDIT_VISITOR_MODAL = "press_edit_visitor_modal",
  PRESS_HOST_NOTIFY = "press_host_notify",
  PRESS_IMPORT_HOST_BTN = "press_import_host_btn",
  PRESS_IMPORT_USER_BTN = "press_import_user_btn",
  PRESS_OUTSIDE_MODAL = "press_outside_modal",
  PRESS_PASS_UPSELLING_READMORE = "press_pass_upselling_readmore",
  PRESS_PASS_UPSELLING_CONTACT = "press_pass_upselling_contact_us",
  PRESS_SUBMIT_FEEDBACK = "press_submit_feedback",
  PRESS_WORKSPACE_ITEM = "press_workspace_item",
  PRESS_X_MODAL = "press_x_modal",
  REMOVE_ROOM = "remove_room",
  REMOVE_VISIT = "remove_visit",
  ROLE_MISMATCH = "role_mismatch",
  SEND_EMAIL_ERROR = "send_email_error",
  SEND_SMS_ERROR = "send_sms_error",
  SIGNED_URL = "signed_url",
  UNLINK_DEVICE = "unlink_device",
  WORKSPACE_SETTINGS = "workspace_settings",
  WORKSPACE_VISIT_TYPES = "workspace_visit_types"
}

export const Logger = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)
  const userId = localStorage.getItem(USER_ID_KEY) || ""
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_LOGGING_API_URL}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return {
    log: async (action: LOG, msg = "") => {
      await axiosInstance.post("/entries/write/log", {
        id: parseInt(userId),
        action,
        msg: `${msg ? `${msg}` : ""}`,
        projectId: process.env.REACT_APP_MESSAGING_API_PROJECT_ID
      })
    },

    error: async (error: LOG, errorInfo: string) => {
      await axiosInstance.post("/entries/write/logerror", {
        id: parseInt(userId),
        error,
        errorStack: `${errorInfo ? `${errorInfo}` : ""}`,
        projectId: process.env.REACT_APP_MESSAGING_API_PROJECT_ID
      })
    }
  }
}
