import { useTranslate, HttpError, useList, useOne } from "@pankod/refine-core"
import { useLocalStorage } from "hooks/useLocalStorage"
import { IRoles, IUserInfo } from "interfaces"
import { useEffect, useMemo } from "react"
import { LOCAL_STORAGE_KEYS } from "utilities/types"
import { getRoleDisplayName } from "./role"
import { SySelectOption } from "components/new/shared/input/SySelect"
import {
  computeAllowedRoles,
  filterRolesForDropdown,
  normalizeRole
} from "./roleMapping"
import { LOG, Logger } from "utilities/logger"
import { ROLE_NAMES } from "modules/Role/role-types"

interface IRoleManagement {
  roleOptions: readonly SySelectOption<string, string>[]
  allowedRoles: string[]
  isLoading: boolean
  error: HttpError | null
  storedUserRole: string | null
}

export const useRoleManagement = (
  currentUserId?: number,
  editingUserId?: number,
  editingUserRoleId?: string
): IRoleManagement => {
  const translate = useTranslate()

  const [ORGANIZATION_OWNER] = ROLE_NAMES

  // 1) My role from local storage (faster than repeated fetch) but also reconcile with API
  const [storedUserRole, setStoredUserRole] = useLocalStorage(
    LOCAL_STORAGE_KEYS.USER_ROLE,
    ""
  )
  const [actAsRoleFlag] = useLocalStorage(
    LOCAL_STORAGE_KEYS.ACT_AS_ROLE,
    "false"
  )

  const actingAsRole = actAsRoleFlag === true && !currentUserId
  const effectiveUserRole = actingAsRole ? ORGANIZATION_OWNER : storedUserRole

  // 2) The logged-in user data from the API (extra check(s))
  const { data: currentUserData } = useOne<IUserInfo>({
    resource: "users",
    id: currentUserId ?? 0,
    queryOptions: {
      enabled: !!currentUserId
    }
  })

  // 3) The user we are editing
  const { data: editingUserData } = useOne<IUserInfo>({
    resource: "users",
    id: editingUserId ?? 0,
    queryOptions: {
      enabled: !!editingUserId
    }
  })

  // Reconcile local storage role with the API (my role, not the editing user’s role)
  useEffect(() => {
    if (currentUserData?.data?.role) {
      const apiRole = currentUserData.data.role.name
      if (apiRole !== storedUserRole) {
        void Logger().log(
          LOG.ROLE_MISMATCH,
          `LocalStorage role (${storedUserRole}) does not match API role (${apiRole}). Updating localStorage.`
        )
        setStoredUserRole(apiRole)
      }
    }
  }, [currentUserData, storedUserRole, setStoredUserRole])

  const {
    data: roleData,
    isLoading,
    error
  } = useList<IRoles>({
    resource: "roles"
  })

  const roles = roleData?.data ?? []
  const currentUserRole: string = effectiveUserRole || ""

  // 4. Compute allowed roles based on the current role and acting status on what roles can be selected.
  const allowedRoles = useMemo(
    () => computeAllowedRoles(currentUserRole, actingAsRole),
    [roles, currentUserRole, actingAsRole]
  )

  // 5. Check if the user being edited is OrganizationOwner (to lock the role)
  const editingUserRoleName = editingUserData?.data?.role?.name
  const isEditingOrganizationOwner =
    editingUserRoleName &&
    normalizeRole(editingUserRoleName) === "organizationowner"

  // Filter roles for the dropdown, ensuring that the current selection is always included.
  // When editing a user, if the selected role is OrganizationOwner,
  // restrict dropdown options to that single role.
  const roleOptions = useMemo(() => {
    // If we are editing a user who is OrganizationOwner, only show that single option.
    if (isEditingOrganizationOwner && editingUserData?.data?.role) {
      const { role: orgOwnerRole } = editingUserData.data
      return [
        {
          value: orgOwnerRole.id.toString(),
          data: getRoleDisplayName(orgOwnerRole.name, translate)
        }
      ]
    }

    // Otherwise, filter based on allowed roles
    const filteredRoles = filterRolesForDropdown(
      roles,
      allowedRoles,
      editingUserRoleId
    )
    return filteredRoles.map((role) => ({
      value: role.id.toString(),
      data: getRoleDisplayName(role.name, translate)
    }))
  }, [
    roles,
    allowedRoles,
    editingUserRoleId,
    translate,
    isEditingOrganizationOwner,
    editingUserData
  ])

  return {
    roleOptions,
    allowedRoles,
    isLoading,
    error: error || null,
    storedUserRole: effectiveUserRole
  }
}
