import {
  useCustomMutation,
  usePermissions,
  useQueryClient,
  useTranslate
} from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useCallback, useEffect, useState } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { checkSettingTranslation } from "utilities/helpers"
import { LOG, Logger } from "utilities/logger"
import { invalidateQueries } from "utilities/queryClientHelper"
import customToast from "utilities/toastHelper"

export const SettingTextArea = ({
  settingsData: {
    value,
    setting_metadata: { key }
  },
  visitTypeId
}: {
  settingsData: ISettingEntity
  visitTypeId?: number
}) => {
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")
  const [realValue, setRealValue] = useState(value)
  const [localValue, setLocalValue] = useState(value)
  const queryClient = useQueryClient()

  const description = checkSettingTranslation(translate(`pages.settings.${key}Description`))
  const workspaceId = localStorage.getItem(SELECTED_WORKSPACE) ?? ""
  const endpoint = visitTypeId ? `/visit-types/${visitTypeId}/settings/${key}` : `/workspaces/${workspaceId}/settings/${key}`

  useEffect(() => {
    setRealValue(value)
    setLocalValue(value)
  }, [value])

  useEffect(() => {
    setLocalValue(realValue)
  }, [realValue])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setLocalValue(event.target.value)
    },
    [setLocalValue]
  )

  const handleBlur = useCallback(() => {
    if (realValue === localValue) return


    updateMutation(
      {
        url: endpoint,
        method: visitTypeId ? "patch" : "put",
        values: { value: localValue }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          void invalidateQueries(queryClient, visitTypeId)
          setRealValue(`${_data.data?.value}`)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().log(LOG.EDIT_SETTING, key)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }, [realValue, localValue, key, translate, updateMutation])

  return (
    <div className="w-full mb-5">
      <h1 className="self-center text-md font-medium">{translate(`pages.settings.${key}`)}</h1>
      <p className="text-gray-500 py-3 text-sm whitespace-pre-wrap">{translate(description || "")}</p>
      <textarea
        disabled={!canEdit}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        cols={50}
        rows={10}
        className="border border-gray-300 text-black rounded-md w-full"
      />
    </div>
  )
}
