import { IRoles } from "interfaces"
import { NormalizedRoleName, RoleName } from "./role-types"

/**
 * Mapping of roles and their allowed sub-roles in the system.
 *
 * This constant defines the hierarchy of roles where each key represents a normalized role name
 * and its corresponding array value contains the list of roles that can be assigned by that role.
 *
 * @example
 * // An organization owner can assign WorkspaceAdmin, FrontDesk, and BasicUser roles
 * ALLOWED_ROLES_MAPPING.organizationowner // ["WorkspaceAdmin", "FrontDesk", "BasicUser"]
 *
 * @readonly
 * @type {Record<NormalizedRoleName, readonly RoleName[]>}
 */
const ALLOWED_ROLES_MAPPING: Record<NormalizedRoleName, RoleName[]> = {
  organizationowner: [
    "OrganizationOwner",
    "WorkspaceAdmin",
    "FrontDesk",
    "BasicUser"
  ],
  workspaceadmin: ["WorkspaceAdmin", "FrontDesk", "BasicUser"],
  frontdesk: [],
  basicuser: []
}

/**
 * Normalizes a role string by converting it to lowercase.
 * @param role - The role string to normalize
 * @returns The normalized role name in lowercase
 */
export const normalizeRole = (role: string): NormalizedRoleName =>
  role.toLowerCase() as NormalizedRoleName

/**
 * Computes the allowed roles based on the stored user role and acting status.
 * @param storedUserRole The role of the user that is stored
 * @param actingAsRole  A boolean that indicates if the user is acting as a role or not
 * @returns An array of allowed roles based on the stored user role and acting status
 */
export const computeAllowedRoles = (
  storedUserRole: string,
  actingAsRole: boolean
): string[] => {
  if (actingAsRole) {
    return ALLOWED_ROLES_MAPPING.organizationowner
  }
  if (!storedUserRole) return []
  const normalizedRole = normalizeRole(storedUserRole)
  return ALLOWED_ROLES_MAPPING[normalizedRole] ?? []
}

/**
 * Filters the roles for the dropdown based on the allowed roles and the selected role.
 * @param roles The list of roles to filter
 * @param allowedRoles The list of allowed roles
 * @param selectedRoleId The id of the selected role
 * @returns The filtered list of roles
 */
export const filterRolesForDropdown = (
  roles: readonly IRoles[],
  allowedRoles: string[],
  selectedRoleId?: string
): readonly IRoles[] => {
  return roles.filter((role) => {
    if (role.id.toString() === selectedRoleId) return true
    return allowedRoles.some(
      (allowed) => normalizeRole(allowed) === normalizeRole(role.name)
    )
  })
}
