import { ClockIcon } from "@heroicons/react/24/outline"
import {
  useTranslate,
  useCustomMutation,
  usePermissions,
  useQueryClient
} from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { checkSettingTranslation } from "utilities/helpers"
import { LOG, Logger } from "utilities/logger"
import { invalidateQueries } from "utilities/queryClientHelper"
import customToast from "utilities/toastHelper"

export const SettingTime = ({
  settingsData: {
    value,
    setting_metadata: { key }
  },
  visitTypeId
}: {
  settingsData: ISettingEntity
  visitTypeId?: number
}) => {
  const translate = useTranslate()
  const { mutate } = useCustomMutation()
  const workspace = localStorage.getItem(SELECTED_WORKSPACE) || ""
  const [time, setTime] = useState<string>(value)
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")

  const hours = Array.from({ length: 24 }, (_, i) => i)
  const minutes = Array.from({ length: 4 }, (_, i) => i * 15)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedHour, setSelectedHour] = useState<string>("")
  const [selectedMinute, setSelectedMinute] = useState<string>("")
  const ref = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()

  const description = checkSettingTranslation(translate(`pages.settings.${key}Description`))
  const endpoint = visitTypeId ? `/visit-types/${visitTypeId}/settings/${key}` : `/workspaces/${workspace}/settings/${key}`

  // Event handler to close the dropdown if clicked outside
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const hourOptions = hours.map((hour) => {
    const value = hour.toString().padStart(2, "0")
    return (
      <option
        data-testid={`setting-hour-option-${value}`}
        key={value}
        value={value}
        className="px-2 rounded-md hover:bg-gray-200 "
      >
        {value}
      </option>
    )
  })

  const minuteOptions = minutes.map((minute) => {
    const value = minute.toString().padStart(2, "0")
    return (
      <option
        data-testid={`setting-minute-option-${value}`}
        key={value}
        value={value}
        className="px-2 rounded-md hover:bg-gray-200"
      >
        {value}
      </option>
    )
  })

  useEffect(() => {
    const [hour, minute] = value.split(":").map(Number)
    if (hour) setSelectedHour(hour.toString().padStart(2, "0"))
    if (minute || minute === 0)
      setSelectedMinute(minute.toString().padStart(2, "0"))
  }, [])

  const handleChangeMinute = (event: ChangeEvent<HTMLSelectElement>) => {
    const minute = event.target.value
    setSelectedMinute(minute)
    setTime(`${selectedHour}:${minute}`)
    handleChangeSetting(minute)
  }

  const handleChangeHour = (event: ChangeEvent<HTMLSelectElement>) => {
    const hour = event.target.value
    setSelectedHour(hour)
    setTime(`${hour}:${selectedMinute}`)
    handleChangeSetting(undefined, hour)
  }

  const handleChangeSetting = (minute?: string, hour?: string) => {
    const newTime = `${hour ?? selectedHour}:${minute ?? selectedMinute}`
    mutate(
      {
        url: endpoint,
        method: visitTypeId ? "patch" : "put",
        values: {
          value: newTime
        }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          void invalidateQueries(queryClient, visitTypeId)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }

  return (
    <div className="flex flex-row flex-1 mt-2 mb-4 mr-2 justify-between items-center">
      <div className="flex flex-col gap-2 justify-between mb-1">
        <h1 className="font-medium text-md">
          {translate(`pages.settings.${key}`)}
        </h1>
        <p className="text-gray-500 text-sm whitespace-pre-wrap">
          {translate(description || "")}
        </p>
      </div>
      <div ref={ref} className="flex flex-col relative w-28">
        <button
          data-testid={`setting-time-${key}`}
          className="flex border border-gray-500 rounded-lg pl-2 py-2 tracking-wide -mr-1"
          disabled={!canEdit}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="items-center mx-auto flex flex-row">
            {time}
            <ClockIcon className="ml-3 h-4 w-4" />{" "}
          </div>
        </button>
        {isOpen && (
          <div className="absolute top-full left-0 z-10">
            <div className="flex flex-col">
              <div className="flex flex-row bg-white mt-1">
                <div className="mx-auto border border-gray-500 rounded-md">
                  <select
                    data-testid={`setting-hour-select`}
                    size={6}
                    className="bg-none text-center px-1 py-1 m-1 border-none focus:ring-0"
                    onChange={handleChangeHour}
                    value={selectedHour}
                  >
                    {hourOptions}
                  </select>
                </div>
                <div className="mx-auto border border-gray-500 rounded-md">
                  <select
                    data-testid={`setting-minute-select`}
                    size={4}
                    className="bg-none text-center px-0 mx-1 border-none focus:ring-0"
                    onChange={handleChangeMinute}
                    value={selectedMinute}
                  >
                    {minuteOptions}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
