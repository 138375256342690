import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { useEffect, useState } from 'react';
import { useCustom, useCustomMutation, useTranslate } from '@pankod/refine-core';
import { SELECTED_WORKSPACE } from 'utilities/constants';
import customToast from 'utilities/toastHelper';
import { Logger, LOG } from 'utilities/logger';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Setting } from 'interfaces';

interface WorkspaceSettingsResponse {
  id: number;
  name: string;
  settings: Setting[];
}

type SearchSettingsState = {
  fullName: string;
  openSearch: string;
  partialName: string;
};

type SelectOptions = "fullName" | "openSearch" | "partialName"

const SETTING_KEYS = {
  fullName: "HostSearchRequireFullName",
  openSearch: "HostSearchNoMinLength",
  partialName: "HostSearchMinInputLength",
};

export const KioskHostSearchSettings = () => {
  const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) ?? "";
  const { data, isLoading, error, refetch } = useCustom<WorkspaceSettingsResponse>({
    url: `workspaces/${selectedWorkspaceId}/settings`,
    method: "get",
  });

  const [state, setState] = useState<SearchSettingsState>();
  const [selectedOption, setSelectedOption] = useState<SelectOptions>();

  const { mutate: updateMutation } = useCustomMutation();
  const translate = useTranslate()

  useEffect(() => {
    if (!isLoading && data?.data) {
      const settings: Setting[] = data?.data?.settings;
  
      setState({
        fullName: settings.find(s => s.setting_metadata.key === SETTING_KEYS.fullName)?.value ?? "false",
        openSearch: settings.find(s => s.setting_metadata.key === SETTING_KEYS.openSearch)?.value ?? "false",
        partialName: settings.find(s => s.setting_metadata.key === SETTING_KEYS.partialName)?.value ?? "3",
      });
    }
  }, [data, isLoading]);

  useEffect(()=> {
    if (state) {
      const selectedOption = checkSelection(state)
      setSelectedOption(selectedOption)
    }
  }, [data, state])

  const checkSelection = (state:SearchSettingsState) => {
    const {fullName, openSearch} = state
    
    if(fullName === "true") return "fullName"
    if(openSearch === "true") return "openSearch"
    return "partialName"
  }

  const updateSetting = (settingKey: string, value: string) => {
    updateMutation(
      {
        url: `workspaces/${selectedWorkspaceId}/settings/${settingKey}`,
        method: "patch",
        values: { value },
      },
      {
        onSuccess: () => {
          customToast.success("Setting updated successfully");
          void Logger().log(LOG.EDIT_SETTING, settingKey);

          const typedRefetch = refetch as () => void; 
          typedRefetch();
        },
        onError: (error) => {
          customToast.error(`Failed to update setting ${settingKey}`);
          console.error(error);

          void Logger().error(
            LOG.EDIT_SETTING,
            `${settingKey}: ${JSON.stringify(error)}`
          )
        },
      }
    );
  };

  const handleClick = (value: SelectOptions) => {
    setSelectedOption(value);
    const newSettings: SearchSettingsState = {
      fullName: String(value === "fullName"),
      openSearch: String(value === "openSearch"),
      partialName: state?.partialName || "3",
    };

    updateSetting(SETTING_KEYS.fullName, newSettings.fullName);
    updateSetting(SETTING_KEYS.openSearch, newSettings.openSearch);
    if (value === "partialName" && newSettings.partialName) {
      updateSetting(SETTING_KEYS.partialName, newSettings.partialName);
    }
  };

  const handlePartialNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (newValue === " ") return;
    
    if (parseInt(newValue) <= 0) {
      newValue = "1"; 
    }
    
    setState(prev => { 
      if (!prev) {
        return { fullName: "false", openSearch: "false", partialName: newValue };
      }
        return { ...prev, partialName: newValue };
    });

    if (selectedOption === "partialName") {
      updateSetting(SETTING_KEYS.partialName, newValue);
    }
  };
  
  if (error !== null) {
    const {statusCode, message} = error;
    customToast.error(`${statusCode} - ${message}`);
  }

  return (
    <RadioGroup value={selectedOption} className="space-y-2">
      <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
        <div className="flex flex-col space-y-2">
          <div className="flex items-start space-x-2">

            {isLoading ? (
              <>
                <Skeleton circle height={16} width={16} />
                <Skeleton width={250} height={24} />
              </>
            ) : (
              <>
                <RadioGroupItem
                value="partialName"
                id="partialName"
                onClick={() => handleClick("partialName")}
                disabled={isLoading}
                />
                <Label htmlFor="partialName" className="font-normal leading-5 text-gray-900">
                  {translate("pages.settings.PartialName")}<br />
                  <span className="text-gray-500 font-thin">
                    {translate("pages.settings.PartialNameDescription")}
                  </span>
                </Label>
              </>
            )}
          </div>
          <div className="flex items-center space-x-2 ml-6">

            {isLoading ? (<>
              <Skeleton width={64} height={36} />
              <Skeleton width={150} height={24} />
            </>) : (<>
              <Input
              type="number"
              className="w-16"
              min="1"
              value={state?.partialName}
              onChange={handlePartialNameChange}
              disabled={selectedOption !== "partialName" || isLoading}
              />
              <span className="font-normal text-sm leading-5 text-gray-900">{translate("pages.settings.Characters")}</span>
            </>)}
          </div>
        </div>

        <div className="flex items-start space-x-2">
          {isLoading ? (<>
            <Skeleton circle height={16} width={16} />
            <Skeleton width={250} height={24} />
          </>) : (<>
            <RadioGroupItem
            value="openSearch"
            id="openSearch"
            onClick={() => handleClick("openSearch")}
            disabled={isLoading}
            />
            <Label htmlFor="openSearch" className="font-normal leading-5 text-gray-900">
                {translate("pages.settings.OpenSearch")}<br />
              <span className="text-gray-500 font-thin">
                {translate("pages.settings.OpenSearchDescription")}
              </span>
            </Label>
          </>)}
        </div>

        <div className="flex items-start space-x-2">
          {isLoading ? (<>
            <Skeleton circle height={16} width={16} />
            <Skeleton width={250} height={24} />
          </>) : (<>
            <RadioGroupItem
            value="fullName"
            id="fullName"
            onClick={() => handleClick("fullName")}
            disabled={isLoading}
            />
            <Label htmlFor="fullName" className="font-normal leading-5 text-gray-900">{translate("pages.settings.FullNameRequired")}</Label>
          </>)}
        </div>
      </SkeletonTheme>
    </RadioGroup>
  );
};

export default KioskHostSearchSettings;

