import { useTranslate } from "@pankod/refine-core";
import { SettingCategory } from "./list";
import React, { Dispatch, SetStateAction } from "react";
import { VisitType } from "interfaces";
import { Button } from "components/ui/button";

interface CategoryButtonProps {
    category: SettingCategory;
    selectedCategory: SettingCategory;
    setSelectedCategory: Dispatch<SetStateAction<SettingCategory>>;
    icon?: React.ReactNode;
    selectedVisitType?: VisitType;
    setSelectedVisitType?: Dispatch<SetStateAction<VisitType | undefined>>;
    visitType?: VisitType;
    visitTypeIndex?: number;
}

export const CategoryButton: React.FC<CategoryButtonProps> = ({
    category,
    selectedCategory,
    setSelectedCategory,
    icon,
    visitType,
    visitTypeIndex,
    selectedVisitType,
    setSelectedVisitType,
}) => {
    const translate = useTranslate()

    const isSelected = category === SettingCategory.VisitType && selectedCategory === SettingCategory.VisitType
        ? selectedVisitType?.id === visitType?.id
        : selectedCategory === category

    return (
        <Button
            data-testid={`button-${category}`}
            key={category}
            variant={isSelected ? "secondary" : "ghost"}
            onClick={() => {
                if (category === SettingCategory.VisitType && setSelectedVisitType) {
                    setSelectedVisitType(visitType)
                }
                setSelectedCategory(category)
            }}
            className={`${isSelected
                ? "bg-one-gray-100"
                : "bg-white"
                } px-2 justify-start hover:bg-one-gray-200`}
        >
            {category === SettingCategory.VisitType ? (
                <>
                    <div className="w-5 h-5 border border-one-gray-300 rounded-full items-center text-center">{visitTypeIndex}</div>
                    {visitType?.name}
                </>
            )
                : (
                    <>
                        {icon && React.cloneElement(icon as React.ReactElement, { className: "text-one-gray-500" })}
                        {translate(`pages.settings.category.${category}`) || category}
                    </>
                )
            }
        </Button>
    )
}