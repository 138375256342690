import { DeviceShow, VisitDeviceList } from "pages/devices"
import { HostsList } from "pages/hosts/list"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition, faInfo } from "@fortawesome/free-solid-svg-icons"
import { SettingsList } from "pages/settings"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { UsersList } from "pages/users/list"
import {
  CalendarProvidersIcon,
  DevicesIcon,
  HostsIcon,
  MonitorIcon,
  SettingsIcon,
  SpacesCubeIcon,
  UsersIcon,
  VisitorFlowIcon,
  VisitorsIcon
} from "icons/icons"
import { Visits } from "pages/visits/list"
import { Onboarding } from "pages/Onboarding"
import { IResourceComponents } from "@pankod/refine-core/dist/interfaces"
import { ReactNode } from "react"
import { permissionType } from "utilities/types"
import { DisplayList, DisplayShow, DisplayCreate } from "pages/displays"
import { CalendarIntegrationList } from "pages/calendarIntegrations/list"
import { VisitorsList } from "pages/visitors/list"
import { SpacesList } from "pages/spaces/list"
import { SpaceShow } from "pages/spaces/show"

export const generateIcon = (
  icon: IconDefinition,
  size?: SizeProp,
  color?: string
) => {
  return <FontAwesomeIcon icon={icon} size={size} color={color} />
}

/**
 * Below is the Refines default interfaces with our permission modification
 */
const auditLogPermissions = ["create", "update", "delete"] as const
type AuditLogPermissions = typeof auditLogPermissions

type OptionsProps<TExtends = { [key: string]: any }> = TExtends & {
  label?: string
  route?: string
  dataProviderName?: string
  auditLog?: {
    permissions?: AuditLogPermissions[number][] | string[]
  }
  [key: string]: any
  permissions?: permissionType[] // this is our custom type
  hide?: boolean
  modules?: string[]
}

export interface ResourceProps extends IResourceComponents {
  name: string
  canDelete?: boolean
  icon?: ReactNode
  options?: OptionsProps
  parentName?: string
  key?: string
}

const resources: ResourceProps[] = [
  {
    name: "onboarding/",
    list: Onboarding,
    options: {
      label: "Onboarding",
      permissions: [permissionType.explicit_deny]
    },
    icon: <FontAwesomeIcon icon={faInfo} />
  },
  {
    name: "visits",
    list: Visits,
    options: {
      label: "Visitor Flow",
      permissions: [permissionType.visit],
      modules: ["visit"]
    },
    icon: <VisitorFlowIcon />
  },
  {
    name: "hosts/",
    list: HostsList,
    options: {
      label: "Hosts",
      permissions: [permissionType.host],
      modules: ["visit"]
    },
    icon: <HostsIcon />
  },
  {
    name: "visitors/",
    list: VisitorsList,
    options: {
      label: "Visitors",
      permissions: [permissionType.visitor],
      modules: ["visit"]
    },
    icon: <VisitorsIcon />
  },
  {
    name: "devices/",
    list: VisitDeviceList,
    show: DeviceShow,
    options: {
      label: "Devices",
      permissions: [permissionType.device],
      modules: ["visit"]
    },
    icon: <DevicesIcon />
  },
  {
    name: "calendar-integrations/",
    list: CalendarIntegrationList,
    options: {
      label: "Calendar Providers",
      permissions: [permissionType.organization],
      modules: ["rooms"]
    },
    icon: <CalendarProvidersIcon />
  },
  {
    name: "spaces/",
    list: SpacesList,
    show: SpaceShow,
    options: {
      label: "Spaces",
      permissions: [permissionType.room],
      modules: ["rooms"]
    },
    icon: <SpacesCubeIcon />
  },
  {
    name: "displays/",
    list: DisplayList,
    show: DisplayShow,
    create: DisplayCreate,
    options: {
      label: "Displays",
      permissions: [permissionType.device],
      modules: ["rooms"]
    },
    icon: <MonitorIcon />
  },
  {
    name: "users/",
    list: UsersList,
    options: {
      label: "Users",
      permissions: [permissionType.user]
    },
    icon: <UsersIcon />
  },
  {
    name: "settings/",
    list: SettingsList,
    options: {
      label: "Settings",
      permissions: [permissionType.setting, permissionType.workspace]
    },
    icon: <SettingsIcon />
  }
]

export { resources }
