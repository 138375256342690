import { useTranslate } from "@pankod/refine-core"
import { WarningTextContainer } from "./WarningTextContainer"

export const PrivacyPolicyNotify = () => {
    const translate = useTranslate()

    return (
        <WarningTextContainer>
            {translate("pages.settings.privacyPolicyNotifyStart")}
            <p className="font-bold">{translate("pages.settings.privacyPolicy")}</p>
            {translate("pages.settings.privacyPolicyNotifyEnd")}
        </WarningTextContainer>
    )
}