import { ExclamationMarkIcon } from "icons/icons"

export const WarningTextContainer = ({ children }) => {
    return (
        <div className="flex flex-row gap-4 -mt-4 bg-one-red-error-light text-one-red-error p-4 rounded-md">
            <div className="w-4 h-4">
                <ExclamationMarkIcon />
            </div>
            <div className="flex flex-row gap-1 flex-wrap">
                {children}
            </div>
        </div>
    )

}
