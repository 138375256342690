import {
  useCustomMutation,
  usePermissions,
  useQueryClient,
  useTranslate
} from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useEffect, useState, useCallback } from "react"
import customToast from "utilities/toastHelper"
import { Logger, LOG } from "utilities/logger"
import { useAppSelector } from "reduxStore/store"
import MarkdownEditor from "react-markdown-editor-lite"
import MarkdownIt from "markdown-it"
import "react-markdown-editor-lite/lib/index.css"
import { Button, ButtonStyle } from "components/globals/Button"
import { checkSettingTranslation } from "utilities/helpers"
import { invalidateQueries } from "utilities/queryClientHelper"

const canEdit = (permissionsData: string) =>
  permissionsData?.includes("All.All") ||
  permissionsData?.includes("Workspace.All")

export const SettingPreArrivalEmailBody = ({
  settingsData: {
    value,
    setting_metadata: { key }
  },
  visitTypeId
}: {
  settingsData: ISettingEntity
  visitTypeId?: number
}) => {
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const [checked, setChecked] = useState(value === "true" ? true : false)
  const { data: permissionsData } = usePermissions<string>()
  const mdParser = new MarkdownIt()
  const [savedValue, setSavedValue] = useState(value)
  const [settingValue, setSettingValue] = useState("")
  const queryClient = useQueryClient()

  const description = checkSettingTranslation(translate(`pages.settings.${key}Description`))
  const endpoint = visitTypeId ? `/visit-types/${visitTypeId}/settings/${key}` : `/workspaces/${selectedWorkspaceId}/settings/${key}`

  const handleEditorChange = useCallback(({ text }: { text: string }) => {
    setSettingValue(text)
  }, [])

  const handleChange = useCallback(() => {
    if (savedValue === settingValue || selectedWorkspaceId === "") {
      return
    }

    updateMutation(
      {
        url: endpoint,
        method: visitTypeId ? "patch" : "put",
        values: { value: settingValue }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          void invalidateQueries(queryClient, visitTypeId)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          setChecked(!checked)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }, [savedValue, settingValue, selectedWorkspaceId])

  useEffect(() => {
    setSavedValue(value)
    setSettingValue(value)
  }, [value])

  const translatedTitle = translate(`pages.settings.${key}`)
  const translatedDescription = description ? translate(description) : ""
  const name = translate("pages.settings.preArrivalEmailBodySave")

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row flex-1 mt-2 mb-4 mr-2 justify-between items-center">
        <div className="flex flex-col gap-2 justify-between mb-1">
          <div className="flex">
            <h1 className="self-center text-md font-medium">
              {translatedTitle}
            </h1>
          </div>
          {translatedDescription && (
            <p className="text-gray-600 text-sm whitespace-pre-wrap">
              {translatedDescription}
            </p>
          )}
          <MarkdownEditor
            className="w-full h-full"
            config={{
              view: { menu: true, md: true, html: true },
              toolbar: {
                items: [
                  "bold",
                  "italic",
                  "header",
                  "img",
                  "link",
                  "ordered-list",
                  "unordered-list"
                ]
              }
            }}
            value={settingValue}
            style={{ height: "100%" }}
            onChange={handleEditorChange}
            renderHTML={(text: string) => mdParser.render(text)}
            imageAccept=".jpg,.png,.jpeg"
            onImageUpload={(file: File) =>
              new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = (data) => resolve((data.target as any).result)
                reader.readAsDataURL(file)
              })
            }
          />
          <div className="flex justify-start">
            <Button
              disabled={
                savedValue === settingValue ||
                selectedWorkspaceId === "" ||
                !canEdit(permissionsData || "")
              }
              type="button"
              style={ButtonStyle.BluePrimary}
              onClick={handleChange}
              name={name}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
