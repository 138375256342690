import { ICustomSetting } from "interfaces"

export interface WorkspaceSettingCategory {
    header: string
    description: string
    cards: SettingCard[]
}

interface SettingCard {
    cardHeader: string
    cardDescription: string
    cardSettings: ICustomSetting[]
}

export const generalWorkspaceSettings: WorkspaceSettingCategory = {
    header: "",
    description: "",
    cards: [
        {
            cardHeader: "pages.settings.basicSettings",
            cardDescription: "",
            cardSettings: [
                {
                    customKey: "WorkspaceName",
                },
            ],
        },
        {
            cardHeader: "pages.settings.localizationSettings",
            cardDescription: "pages.settings.localizationSettingsDescription",
            cardSettings: [
                {
                    customKey: "Languages",
                },
                {
                    key: "TimeZone",
                }
            ],
        },
        {
            cardHeader: "pages.settings.workspaceAddressCardHeader",
            cardDescription: "pages.settings.workspaceAddressCardDescription",
            cardSettings: [
                {
                    key: "WorkspaceAddress",
                }
            ],
        }
    ],
}

export const guestWorkspaceSettings: WorkspaceSettingCategory = {
    header: "pages.settings.category.guest",
    description: "pages.settings.guestSettingsDescription",
    cards: [
        {
            cardHeader: "pages.settings.kioskHostSearchSettings",
            cardDescription: "pages.settings.kioskHostSearchSettingsDescription",
            cardSettings: [
                {
                    customKey: "KioskHostSearchSettings",
                },
            ],
        },
        {
            cardHeader: "pages.settings.automaticDataDeletion",
            cardDescription: "pages.settings.automaticDataDeletionDescription",
            cardSettings: [
                {
                    key: "VisitorDataExpirationDays",
                },
                {
                    customKey: "PrivacyPolicyNotify"
                }
            ],
        }],
}

export const notificationsWorkspaceSettings: WorkspaceSettingCategory = {
    header: "pages.settings.notifications",
    description: "",
    cards: [
        {
            cardHeader: "pages.settings.hostNotificationSettings",
            cardDescription: "pages.settings.hostNotificationSettingsDescription",
            cardSettings: [
                {
                    key: "SendSms",
                },
                {
                    key: "SendEmail",
                },
            ],
        },
        {
            cardHeader: "pages.settings.expectedVisitEmailSettings",
            cardDescription: "pages.settings.expectedVisitEmailSettingsDescription",
            cardSettings: [
                {
                    key: "SendExpectedEmail",
                },
                {
                    key: "SendExpectedSms",
                },
            ],
        },
        {
            cardHeader: "pages.settings.lobbyNotificationSettings",
            cardDescription: "pages.settings.lobbyNotificationSettingsDescription",
            cardSettings: [
                {
                    key: "SendLobbyNotifications",
                },
                {
                    key: "LobbyEmailAddress",
                },
                {
                    key: "LobbyPhoneNumber",
                },
            ],
        },
    ],
}

// Temporary setting list. Should be under visit type settings
export const flowWorkspaceSettings: WorkspaceSettingCategory = {
    header: "pages.settings.flow",
    description: "",
    cards: [
        {
            cardHeader: "pages.settings.visitor",
            cardDescription: "pages.settings.visitorSettingsDescription",
            cardSettings: [
                {
                    key: "AskVisitorEmail",
                    dataTypeOverride: "Dropdown"
                },
                {
                    key: "AskVisitorPhone",
                    dataTypeOverride: "Dropdown"
                },
                {
                    key: "TakePhoto"
                },
                {
                    key: "PrintCard"
                },
                {
                    key: "AutomaticVisitorSignOut"
                },
            ]
        },
        {
            cardHeader: "pages.settings.preArrivalEmailSettings",
            cardDescription: "pages.settings.preArrivalEmailSettingsDescription",
            cardSettings: [
                {
                    key: "PreArrivalEmailSubject"
                },
                {
                    key: "PreArrivalEmailHeaderImageUrl"
                },
                {
                    key: "PreArrivalEmailVisitHost"
                },
                {
                    key: "PreArrivalEmailVisitHostEmail"
                },
                {
                    key: "PreArrivalEmailVisitHostPhone"
                },
                {
                    key: "PreArrivalEmailVisitDateTime"
                },
                {
                    key: "PreArrivalEmailVisitOrganizationAddress"
                },
                {
                    key: "PreArrivalEmailBody",
                    dataTypeOverride: "PreArrivalEmailBody"
                },
            ]
        }
    ]
}

export const visitTypeSettings: WorkspaceSettingCategory = {
    header: "pages.settings.visitType",
    description: "",
    cards: [
        {
            cardHeader: "pages.settings.general",
            cardDescription: "",
            cardSettings: [
                {
                    visitTypeKey: "VirtualVisitorBadgeEmail",
                },
                {
                    visitTypeKey: "VirtualVisitorBadgeSms",
                },
                {
                    visitTypeKey: "CalendarInvitationEmail",
                },
                {
                    visitTypeKey: "PassInductionUrl",
                },
            ],
        },
        {
            cardHeader: "pages.settings.termsOfVisit",
            cardDescription: "pages.settings.termsOfVisitDescription",
            cardSettings: [
                {
                    visitTypeKey: "TermsOfVisit"
                },
                {
                    customKey: "TermsOfVisit"
                },
            ],
        },
    ],
}

export const privacyPolicyWorkspaceSettings: WorkspaceSettingCategory = {
    header: "pages.settings.privacyPolicy",
    description: "",
    cards: [
        {
            cardHeader: "pages.settings.privacyPolicy",
            cardDescription: "pages.settings.privacyPolicyDescription",
            cardSettings: [
                {
                    customKey: "PrivacyPolicy"
                },
            ],
        },
    ],
}

export const roomsBrandingWorkspaceSettings: WorkspaceSettingCategory = {
    header: "pages.settings.branding",
    description: "",
    cards: [
        {
            cardHeader: "pages.settings.roomsBrandingSubHeader",
            cardDescription: "pages.settings.roomsBrandingDescription",
            cardSettings: [
                {
                    key: "RoomsLogo",
                },
                {
                    key: "RoomsBackgroundPicture",
                },
            ],
        },
    ],
}

export const roomsEnergySaverWorkspaceSettings: WorkspaceSettingCategory = {
    header: "pages.settings.energySaver",
    description: "",
    cards: [
        {
            cardHeader: "pages.settings.workingHoursTitle",
            cardDescription: "pages.settings.roomsPowerSaveDescription",
            cardSettings: [
                {
                    key: "WorkingHours",
                },
                {
                    key: "WorkingHoursStart",
                },
                {
                    key: "WorkingHoursEnd",
                },
            ],
        },
    ],
}