import { SETTING_CARD_EXTRAS } from "utilities/types"
import { WorkspaceNameChange } from "./WorkspaceNameChange"
import { WorkspaceLanguageSettings } from "./WorkspaceLanguageSettings"
import { PrivacyPolicyNotify } from "./PrivacyPolicyNotify"
import { TermsOfVisit } from "./TermsOfVisit"
import { VisitType } from "interfaces"
import { PrivacyPolicy } from "./PrivacyPolicy"
import { KioskHostSearchSettings } from "./KioskHostSearchSettings"

export const CustomSetting = (name: string, index: number, visitType?: VisitType) => {
    switch (name) {
        case SETTING_CARD_EXTRAS.LANGUAGES:
            return <WorkspaceLanguageSettings key={index} />
        case SETTING_CARD_EXTRAS.TERMS_OF_VISIT:
            return <TermsOfVisit key={index} visitType={visitType} />
        case SETTING_CARD_EXTRAS.PRIVACY_POLICY:
            return <PrivacyPolicy key={index} />
        case SETTING_CARD_EXTRAS.PRIVACY_POLICY_NOTIFY:
            return <PrivacyPolicyNotify key={index} />
        case SETTING_CARD_EXTRAS.WORKSPACE_NAME:
            return <WorkspaceNameChange key={index} />
        case SETTING_CARD_EXTRAS.KIOSK_HOST_SEARCH_SETTINGS:
            return <KioskHostSearchSettings key={index} />
        default:
            return null
    }
}