import {
  useCustomMutation,
  usePermissions,
  useQueryClient,
  useTranslate
} from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useState, useEffect } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { checkSettingTranslation } from "utilities/helpers"
import { Logger, LOG } from "utilities/logger"
import { invalidateQueries } from "utilities/queryClientHelper"
import customToast from "utilities/toastHelper"

export const SettingSingleLineText = ({
  settingsData: {
    value,
    setting_metadata: { key, data_type }
  },
  visitTypeId
}: {
  settingsData: ISettingEntity
  visitTypeId?: number
}) => {
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")
  const [savedValue, setSavedValue] = useState(value)
  const [settingValue, setSettingValue] = useState("")
  const [settingDataType, setSettingDataType] = useState("")
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const isEmail = (email: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  const description = checkSettingTranslation(translate(`pages.settings.${key}Description`))
  const queryClient = useQueryClient()

  const workspaceId = localStorage.getItem(SELECTED_WORKSPACE) ?? ""
  const endpoint = visitTypeId ? `/visit-types/${visitTypeId}/settings/${key}` : `/workspaces/${workspaceId}/settings/${key}`

  useEffect(() => {
    setSavedValue(value)
  }, [value])

  useEffect(() => {
    setSettingValue(value)
    setSettingDataType(data_type.name)
  }, [value, data_type])

  const updateSetting = (value: string) => {
    if (savedValue === settingValue || workspaceId === "") {
      return
    }

    if (
      settingDataType === "email" &&
      value !== "" &&
      isEmail(value) === false
    ) {
      return
    }

    updateMutation(
      {
        url: endpoint,
        method: visitTypeId ? "patch" : "put",
        values: { value }
      },
      {
        onSuccess: (data, _variables, _context) => {
          void invalidateQueries(queryClient, visitTypeId)
          setSavedValue(data.data?.value as string)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().log(LOG.EDIT_SETTING, key)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }

  return (
    <div className="flex flex-col gap-2 flex-1 mb-8 w-full">
      <h1 className="text-md font-medium">{translate(`pages.settings.${key}`)}</h1>
      <input
        data-testid={`setting-input-${key}`}
        disabled={!canEdit}
        type={settingDataType}
        className={`bg-white w-full border text-sm rounded-md block p-2.5 ${!canEdit
          ? "!bg-gray-100 border-gray-300 cursor-not-allowed"
          : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:border-2"
          }`}
        value={settingValue}
        onChange={(event) => setSettingValue(event.target.value)}
        onBlur={() => updateSetting(settingValue)}
      ></input>
      <p className="text-gray-600 text-sm whitespace-pre-wrap">{translate(description || "")}</p>
    </div>
  )
}
