import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  useCustomMutation,
  useOne,
  usePermissions,
  useTranslate
} from "@pankod/refine-core"
import { IWorkspaceLanguages, WorkspaceLanguage } from "interfaces"
import { useState, useEffect, useRef } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { Logger, LOG } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { Language } from "utilities/types"

export const WorkspaceLanguageSettings = () => {
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")
  const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) ?? ""
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const [languages, setLanguages] = useState<WorkspaceLanguage[]>([])

  const { data: allLanguages } = useOne<Language[]>({
    resource: "languages",
    id: ""
  })

  const { data: languageData } = useOne<IWorkspaceLanguages>({
    resource: `workspaces/${selectedWorkspaceId}/languages`,
    id: ""
  })
  useEffect(() => {
    setLanguages(languageData?.data?.languages ?? [])
  }, [languageData])

  const updateLanguages = (
    values: WorkspaceLanguage[],
    isDefaultLanguage: boolean
  ) => {
    if (values.length === 0) {
      return
    }

    const resourceObjectName = isDefaultLanguage
      ? `resources.WorkspaceDefaultLanguage`
      : `resources.WorkspaceLanguages`

    updateMutation(
      {
        url: `workspaces/${selectedWorkspaceId}/languages`,
        method: "patch",
        values: {
          values: values.map((lang) => ({
            language_id: lang.language.id,
            is_primary: lang.is_primary
          }))
        }
      },
      {
        onSuccess: (data, _variables, _context) => {
          setLanguages(values)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(resourceObjectName).toLowerCase()
            })
          )
          void Logger().log(LOG.EDIT_SETTING, "WorkspaceLanguages")
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(resourceObjectName).toLowerCase()
            })
          )
          void Logger().error(
            LOG.EDIT_SETTING,
            `${resourceObjectName}: ${JSON.stringify(_error)}`
          )
        }
      }
    )
  }

  return (
    <div className="flex flex-col gap-2 flex-1 mb-8 w-full">
      <h1 className="text-md font-medium">
        {translate("pages.settings.defaultLanguage")}
      </h1>
      <DefaultLanguagePicker
        allLanguages={allLanguages?.data ?? []}
        languageData={languages}
        disabled={!canEdit}
        updateLanguages={updateLanguages}
      />
      <h1 className="text-md font-medium">
        {translate("pages.settings.availableLanguages")}
      </h1>
      <p className="text-one-gray-700">
        {translate("pages.settings.availableLanguagesDescription")}
      </p>
      <AvailableLanguages
        allLanguages={allLanguages?.data ?? []}
        languageData={languages}
        disabled={!canEdit}
        updateLanguages={updateLanguages}
      />
    </div>
  )
}

const DefaultLanguagePicker = ({
  allLanguages,
  languageData,
  disabled,
  updateLanguages
}: {
  allLanguages: Language[]
  languageData: WorkspaceLanguage[]
  disabled: boolean
  updateLanguages: (
    values: WorkspaceLanguage[],
    isDefaultLanguage: boolean
  ) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  // Event handler to close the dropdown if clicked outside
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }
  const [defaultLanguage, setDefaultLanguage] = useState<Language>()

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!defaultLanguage) {
      setDefaultLanguage(
        languageData.find((lang) => lang.is_primary === true)?.language
      )
    }
  }, [languageData])

  function handleDefaultLanguageChange(lang: Language) {
    // Set current default language to false
    const currentDefaultLanguage = languageData.find(
      (l) => l.is_primary === true
    )
    if (currentDefaultLanguage) {
      currentDefaultLanguage.is_primary = false
    }

    const langs = [...languageData]
    // Append langs to include lang with is_primary = true
    if (!langs.find((l) => l.language.tag === lang.tag)) {
      langs.push({
        is_primary: true,
        language: lang
      })
    } else {
      // Set is_primary to true
      const index = langs.findIndex((l) => l.language.tag === lang.tag)
      const currentLang = langs[index]
      if (currentLang !== undefined) {
        currentLang.is_primary = true
      }
    }

    updateLanguages(langs, true)
  }

  return (
    <div
      ref={ref}
      className="flex relative ring-1 ring-inset rounded-md ring-gray-300 w-full"
    >
      <button
        className={`h-full w-full p-2 ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {defaultLanguage && (
          <div className="flex flex-row justify-between items-center">
            <DefaultLanguageOption language={defaultLanguage} />
            <FontAwesomeIcon
              className="pr-2 text-one-gray-500"
              icon={isOpen ? faCaretUp : faCaretDown}
              size="lg"
            />
          </div>
        )}
      </button>
      {isOpen && (
        <div className="absolute top-11 w-full bg-white flex flex-col ring-1 ring-inset rounded-md ring-gray-300 shadow-md">
          {allLanguages.map((lang) => {
            if (lang.tag === defaultLanguage?.tag) {
              return null
            }
            return (
              <button
                className="flex w-full p-0.5"
                key={`btn_${lang.name}`}
                onClick={() => {
                  setDefaultLanguage?.(lang)
                  handleDefaultLanguageChange(lang)
                  setIsOpen(false)
                }}
              >
                <div className="transition duration-100 hover:bg-one-gray-100 p-2 w-full">
                  <DefaultLanguageOption language={lang} />
                </div>
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

const DefaultLanguageOption = ({ language }: { language: Language }) => {
  return (
    <div
      key={language.tag}
      className="flex flex-row gap-2 items-center w-full "
    >
      <div className="bg-one-gray-500 rounded-md text-white w-7 h-6 px-1">
        {language.tag?.substring(0, 2).toUpperCase()}
      </div>
      {language.name}
    </div>
  )
}

const AvailableLanguages = ({
  allLanguages,
  languageData,
  disabled,
  updateLanguages
}: {
  allLanguages: Language[]
  languageData: WorkspaceLanguage[]
  disabled: boolean
  updateLanguages: (
    values: WorkspaceLanguage[],
    isDefaultLanguage: boolean
  ) => void
}) => {
  const [defaultLanguage, setDefaultLanguage] = useState<Language>()

  useEffect(() => {
    setDefaultLanguage(
      languageData.find((lang) => lang.is_primary === true)?.language
    )
  }, [languageData])

  function toggleLanguage(lang: Language) {
    if (lang.tag === defaultLanguage?.tag) {
      return
    }

    const langs = [...languageData]

    if (langs.find((l) => l.language.tag === lang.tag)) {
      // Remove lang from langs
      const index = langs.findIndex((l) => l.language.tag === lang.tag)
      langs.splice(index, 1)
    } else {
      langs.push({
        language: lang,
        is_primary: false
      })
    }

    updateLanguages(langs, false)
  }

  return (
    <div className="w-full ring-1 ring-inset ring-gray-300 rounded-md p-0.5 flex flex-col">
      {languageData &&
        defaultLanguage &&
        allLanguages.map((lang) => (
          <LanguageCheckbox
            defaultLanguage={defaultLanguage}
            key={lang.tag}
            language={lang}
            checked={
              languageData.find((l) => l.language.tag === lang.tag) !==
              undefined
            }
            forceDisabled={disabled}
            toggleLanguage={toggleLanguage}
          />
        ))}
    </div>
  )
}

const LanguageCheckbox = ({
  defaultLanguage,
  language,
  checked,
  forceDisabled,
  toggleLanguage
}: {
  defaultLanguage: Language
  language: Language
  checked: boolean
  forceDisabled?: boolean
  toggleLanguage: (lang: Language) => void
}) => {
  const isDefault = language.tag === defaultLanguage.tag

  function handleChange() {
    if (isDefault) {
      return
    }
    toggleLanguage(language)
  }

  return (
    <button
      disabled={forceDisabled || isDefault}
      className={`flex flex-row w-full p-2 gap-2 items-center ${!isDefault && !forceDisabled && "hover:bg-one-gray-100"
        }`}
      onClick={() => handleChange()}
    >
      <input
        data-testid={`language-${language.tag}`}
        disabled={forceDisabled || isDefault}
        type="checkbox"
        onChange={(e) => {
          handleChange()
        }}
        className={`accent-one-gray-950 rounded-md w-5 h-5 ring-0 ${forceDisabled || isDefault
          ? "text-one-gray-300"
          : "checked:bg-one-gray-950 focus:accent-one-gray-950 text-one-gray-950"
          }`}
        checked={checked}
      />
      <p className={isDefault ? "text-one-gray-300" : "text-black"}>
        {language.name}
      </p>
    </button>
  )
}
