import { useTranslate } from "@pankod/refine-core";
import { ISettingEntity, IWorkspaceSettings, VisitType } from "interfaces";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { flowWorkspaceSettings, generalWorkspaceSettings, guestWorkspaceSettings, notificationsWorkspaceSettings, privacyPolicyWorkspaceSettings, roomsBrandingWorkspaceSettings, roomsEnergySaverWorkspaceSettings, visitTypeSettings, WorkspaceSettingCategory } from "./WorkspaceSettings"
import { SettingCategory } from "./list";
import { ScrollArea } from "components/ui/scroll-area";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card";
import { SettingBuilder } from "./SettingBuilder";
import { Button } from "components/ui/button";
import { CustomSetting } from "./builder-components/CustomSetting";

interface CategoryContainerProps {
    category: string;
    data: IWorkspaceSettings | undefined;
    visitType: VisitType | undefined;
}

export const SettingCategoryContainer: React.FC<CategoryContainerProps> = ({ category, data, visitType }) => {
    const translate = useTranslate()
    const [categorySettings, setCategorySettings] = useState<WorkspaceSettingCategory>()
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const sectionRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
    const [activeSection, setActiveSection] = useState<string | null>(null);

    useEffect(() => {
        const categorySettingsMap: { [key in SettingCategory]: WorkspaceSettingCategory | (() => WorkspaceSettingCategory) } = {
            [SettingCategory.General]: generalWorkspaceSettings,
            [SettingCategory.Guest]: guestWorkspaceSettings,
            [SettingCategory.Notifications]: notificationsWorkspaceSettings,
            [SettingCategory.VisitType]: visitTypeSettings,
            [SettingCategory.Privacy]: privacyPolicyWorkspaceSettings,
            [SettingCategory.Flow]: flowWorkspaceSettings,
            [SettingCategory.Branding]: roomsBrandingWorkspaceSettings,
            [SettingCategory.EnergySaver]: roomsEnergySaverWorkspaceSettings,
        };

        setCategorySettings(categorySettingsMap[category] as WorkspaceSettingCategory);
    }, [category, visitType]);

    // Initialize card headers for quick nav sections
    const sections = useMemo(() => {
        if (!categorySettings?.cards) return [];

        categorySettings.cards.forEach((card) => {
            if (!sectionRefs.current[card.cardHeader]) {
                sectionRefs.current[card.cardHeader] = React.createRef<HTMLDivElement>();
            }
        });

        return categorySettings.cards.map((card) => ({
            id: card.cardHeader,
            ref: sectionRefs.current[card.cardHeader],
        }));
    }, [categorySettings]);

    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    useEffect(() => {
        const scrollElement = scrollContainerRef.current?.querySelector("[data-radix-scroll-area-viewport]");

        if (!scrollElement) return;

        const handleScroll = () => {
            const scrollTop = (scrollElement as HTMLDivElement).scrollTop;
            let currentSection: string | null = null;

            for (const sec of sections) {
                const sectionElement = sec.ref?.current;
                if (sectionElement) {
                    const offsetTop = sectionElement.offsetTop - scrollElement.getBoundingClientRect().top;
                    if (scrollTop >= offsetTop - 100) { // Adjust threshold as needed
                        currentSection = sec.id;
                    }
                }
            }

            // Detect if on bottom of the list
            if (scrollTop + scrollElement.clientHeight >= scrollElement.scrollHeight) {
                currentSection = sections[sections.length - 1]?.id || currentSection;
            }

            setActiveSection(currentSection);
        };

        scrollElement.addEventListener("scroll", handleScroll);
        handleScroll(); // Run once on mount

        return () => {
            scrollElement.removeEventListener("scroll", handleScroll);
        };
    }, [sections]);

    return (
        <div className="flex flex-row gap-4 w-full">
            <ScrollArea ref={scrollContainerRef} className="w-full h-[calc(100vh-7.5rem)]">
                <div className="flex flex-col w-full px-2">
                    {categorySettings && (
                        <>
                            <div className="flex flex-col pb-4">
                                <h1 className="text-lg font-medium">
                                    {visitType && category === SettingCategory.VisitType ?
                                        visitType?.name :
                                        translate(categorySettings.header) || translate(`pages.settings.category.${category}`)}
                                </h1>
                                <p className="text-sm text-one-gray-500">
                                    {visitType && category === SettingCategory.VisitType ?
                                        visitType?.description :
                                        translate(categorySettings.description)}
                                </p>
                            </div>
                            <div className="flex flex-col gap-4">
                                {
                                    categorySettings.cards.map((card, i) => {
                                        const desc = translate(card.cardDescription)
                                        const cardRef = sectionRefs.current[card.cardHeader];

                                        return (
                                            <Card key={card.cardHeader} ref={cardRef}>
                                                <CardHeader>
                                                    <CardTitle>
                                                        {translate(card.cardHeader)}
                                                    </CardTitle>
                                                    {desc && (
                                                        <CardDescription>
                                                            {desc}
                                                        </CardDescription>
                                                    )}
                                                </CardHeader>
                                                <CardContent>
                                                    {card.cardSettings.map((setting, i) => {
                                                        /// Render custom settings such as workspace languages component
                                                        if (setting.customKey) {
                                                            return CustomSetting(setting.customKey, i, visitType)
                                                        }

                                                        /// Render normal workspace settings
                                                        if (setting.key) {                                                            
                                                            const dataSetting = data?.settings as ISettingEntity[]
                                                            if (!dataSetting) return null

                                                            const settingData = dataSetting.find((s: ISettingEntity) =>
                                                                s.setting_metadata.key === setting.key)

                                                            if (!settingData) return null

                                                            return (
                                                                SettingBuilder(settingData, i, setting.dataTypeOverride as string)
                                                            )
                                                        }

                                                        /// Render visit type settings.
                                                        if (setting.visitTypeKey) {
                                                            const visitTypeSettings = visitType?.settings as unknown as ISettingEntity[]
                                                            if (!visitTypeSettings) return null

                                                            const settingData = visitTypeSettings.find((s: ISettingEntity) =>
                                                                s.setting_metadata.key === setting.visitTypeKey)

                                                            if (!settingData) return null

                                                            return (
                                                                SettingBuilder(settingData, i, undefined, visitType?.id as number)
                                                            )
                                                        }

                                                        return null
                                                    })}
                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </ScrollArea>
            {sections && (
                <div className="flex flex-col w-56">
                    {sections.map((section) => (
                        <div key={`nav_${section}`} className="flex flex-row">
                            <div className={`h-full border border-l-1 ` + (activeSection === section.id ? "border-one-gray-900" : "border-one-gray-100")} />
                            <Button
                                key={section.id}
                                className={activeSection === section.id ? "text-one-gray-900" : "text-one-gray-500"}
                                variant={"nav"}
                                size={"auto"}
                                onClick={() => section.ref && scrollToSection(section.ref)}>
                                {translate(section.id)}
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}